<!-- dialog -->
<template>
  <div class="publish-dialog">
   
    <el-dialog :visible.sync="show" :show-close="false" :before-close="handleClose">
      <div class="div-dialog">
        <img class="close_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png"  @click="backHome">
        <img class="success_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/dialog_success1.png" >
        <p class="dialog_title">内容已更新，快去体验吧～</p>
        <div class="dialog_btn1" @click="backHome">返回首页</div>
        <div class="dialog_btn2" @click="jumbShare">
          <p class="btn2_p1">前往体验</p>
        
        </div>
      </div>
  
    </el-dialog>
  
  </div>
  </template>
  
  <script>
  export default {
  data() {
  return {
    show: false,
    overlay: false,
      empId: null,
      oid: null
    
  }
    },
    methods: {
      showPopup1 (params,param2) {
        this.empId = params;
        this.oid = param2;
        this.show = true;
      },
    

      jumbShare () {
        this.show = false;
          window.open(this.$common.outAddress() + 'conversation?' + this.$Base64.encodeURI('id=' + JSON.stringify([parseInt(this.empId + "")])));
          window.close();

      },
  
      backHome () { 
        this.show = false;
         //跳转首页 刷新
         var reload = localStorage.getItem('reloadWorkbenches');
         localStorage.setItem('reloadWorkbenches', parseInt(reload) + 1);
            window.close();

        
      },
      handleClose(done) {
          
        }
    },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
  
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
  
  }
  }
  </script>
  <style scoped>
  /* @import url(); 引入css类 */
  .div-dialog{
    display: flex;
    flex-direction: column;
  }
  .close_dialog{
    width: 36px;
  height: 36px;
  margin-top: 12px;
  margin-right: 12px;
  align-self: flex-end;
  }
  .success_dialog{
    width: 160px;
  height: 160px;
  margin-top: 8px;
  
  align-self: center;
  }
  .dialog_title{
    font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  margin-top: 18px;
  
  align-self: center;
  color: #000000;
  }
  .dialog_btn1{
    width: 348px;
    align-self: center;
    height: 60px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 60px;
  margin-top: 40px;
  text-align: center;
  }
  
  
  .dialog_btn2{
    display: flex;
    width: 348px;
    align-self: center;
    height: 60px;
    background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #D7E4F3;
  margin-top: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  
  .btn2_p1{
    font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 16px
  }
  .btn2_p2{
    font-size: 10px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 10px;
  margin-top: 6px;
  }
  
  .publish-dialog /deep/.el-dialog {
  
    width: 400px;
  height: 461px;
  background: linear-gradient(180deg, #EAF1FA 0%, #FFFFFF 100%);
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 1px solid #FFFFFF;
  }
  .publish-dialog /deep/.el-dialog__header {
  
    display: none;
  
  }
  .publish-dialog /deep/.el-dialog__body {
  
    padding:0;
  }
  .pop-content{
    display: flex;
  }
  </style>