<!--  -->
<template>
  <div>
    <div>

      <div class="div-prompt">
        <div class="head-box">
          <img :src="empHeader">
          <p>当前角色：{{ empType }}</p>
        </div>
        <div class="head-t">
          <p class="head-p">请为角色选择预设的prompt：</p>
          <p class="head-p2">(你可以自定义，也可以在预设的角色上进行修改，只生效当前选择的)</p>
        </div>
        
        <ul class="prompt-list">

          <li v-for="(item, index) in industryList" :key="index" class="prompt-list-li" @click="changeSelectHead(index)"
            :class="{ 'prompt-active': selectIndex == index }">{{ item.promptName }}
          </li>

        </ul>
      </div>
      <el-input type="textarea" placeholder="请为角色选择专属prompt,或自定义输入prompt" v-model="content">
      </el-input>
      <div class="bottom-tip">
        <i class="el-icon-info"></i>
        <p class="tip-p">提示:prompt是数字员工的“大脑”可以通过修改prompt调整数字员工回答的内容，prompt越清晰，数字员工会更智能</p>
        <p class="tip-fresh" @click="resetPrompt"> <i class="el-icon-refresh"></i>把prompt重置为空</p>

      </div>

      <div class="div-sub"> <el-button type="primary" :disabled="!content" :loading="btnloading"
          @click="baocunPrompt">保存内容</el-button>
        
          <button class="cancle-btn" v-if="!editFlag" @click="cancleEdit">
          取消编辑
        </button>
        </div>
    </div>

    <el-dialog :visible.sync="show" :show-close="false">
      <div class="div-dialog">
        <div class="dialog-head">
          <p class="dialog-head-p">操作提示</p>
          <img class="close_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png"
            @click="show = false">
        </div>

        <div class="dialog-content">

          <p class="dialog-p4">{{ dialogcontent }}</p>

          <p class="dialog-p5" v-if="dialogType == 0">切换后，您修改的内容会丢失</p>

        </div>

        <div class="dialog-bottom">

          <button class="dialog-negative" @click="show = false">取消</button>
          <button class="dialog-positive" @click="dialogConfim">确认</button>
        </div>
      </div>

    </el-dialog>

    <PublishDialog2 ref="childShare2"></PublishDialog2>
  </div>
</template>

<script>
import common from "common/js/common";
import PublishDialog2 from "./PublishDialog2.vue";
import { industry, updatePrompt, findEmpById } from "api/aiworker/aiworker.js";
import { formToJSON } from "axios";
export default {
  components: {
    PublishDialog2
  },
  data () {
    return {
      btnloading: false,
      show: false,
      isdisabled: true,
      selectIndex: 0,
      tempIndex: 0,
      dialogType: 0,
      content: "",
      empId: null,
      orgId:null,
      empHeader: "",
      empType: "",
      industryId: null,
      industryList: [
        {
          "id": null,
          "promptName": "自定义",
          "prompt": ""
        }
      ],
      dialogcontent: "是否确定替换当前的prompt？",
      editFlag:null,

    }
  },
  methods: {
    cancleEdit () { 
      //history.back()
      window.close();
    },
    showDialog (parm,parm2) {

this.$refs.childShare2.showPopup1(parm,parm2);
},
    changeSelectHead (index) {
      this.tempIndex = index;
      if (!common.isStringEmpty(this.content)) {
        this.dialogType = 0;
        this.dialogcontent = "是否确定替换当前的prompt？";
        this.show = true;
      } else {
        this.selectIndex = index;
        this.content = this.industryList[index].prompt;
      }
    },
    baocunPrompt () {
      let obj = {
        "empId": this.empId,
        "industryId": this.industryList[this.selectIndex].id,
        "content": this.content
      }
      updatePrompt(obj).then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          if (!common.isStringEmpty(this.content)) {
            this.showDialog(this.empId,this.orgId);
          }
         
        } else {
          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }

        }
      }).catch((err) => {
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }

      });
    },
    dialogConfim () {
      this.show = false;
      if (this.dialogType == 0) {
        this.selectIndex = this.tempIndex;
        this.content = this.industryList[this.selectIndex].prompt;
      } else if (this.dialogType == 1) {
        this.content = "";
        this.baocunPrompt();
      } else if (this.dialogType == 2) {
        this.$emit("update");
      }

    },
    resetPrompt () {
      this.dialogType = 1;
      this.dialogcontent = "是否确定重置prompt为空？";
      this.show = true;
    },
    remindPrompt () {
      this.dialogType = 2;
      if (!common.isStringEmpty(this.content)) {
        this.dialogcontent = "当前页面内容未保存，是否确认退出？";
        this.show = true;
      } else {
        this.$emit("update");
      }

    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.editFlag = this.$route.query.flag;
    this.empId = this.$Base64.decode(this.$route.query.eid);
    console.log(this.$route.query.eid);
    console.log(this.empId);
  
    findEmpById(this.empId).then((res) => {
      console.log(res);
      if (res.code == 200) {
        this.empHeader = res.data.empHeader;
        this.empType = res.data.empType;
        this.orgId = res.data.orgId;
        if (!common.isStringEmpty(res.data.industryId)) {
          this.industryId = res.data.industryId;
        }
        if (!common.isStringEmpty(res.data.content)) {
          this.content = res.data.content;
        }
        this.$emit("updateData",this.empId,this.orgId);
        industry().then((res2) => {
          if (res2.code == 200) {
            this.industryList = this.industryList.concat(res2.data);
            if (!common.isStringEmpty(this.industryId)) {

              for (var i = 0; i < res2.data.length; i++) {

                if (this.industryId == res2.data[i].id) {
                  this.tempIndex =  i + 1 ;
                  this.selectIndex = i + 1;
                  break;
                }
              }
            }
            console.log(this.industryList);
          } else {
            if (!common.isStringEmpty(res2.message)) {
              this.$message(res2.message);
            }

          }
        }).catch((err) => {
          if (!common.isStringEmpty(JSON.stringify(err))) {
            this.$message(JSON.stringify(err));
          }

        });


      } else {


      }
    }).catch((err) => {

    });


  }

}
</script>
<style scoped>
/* @import url(); 引入css类 */
.div-prompt {

  width: 100%;
  margin-bottom: 16px;
  background: linear-gradient(180deg, #ECF5FF 0%, #F5FAFF 100%);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #E7F1FB;
}

.head-box {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-top: 16px;
}

.head-box p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 14px;
  margin-left: 4px;
}

.head-box img {

  width: 24px;
  height: 24px;
  border-radius: 12px;


}

.head-t{
  display: flex;
  align-items: flex-end;
  margin-left: 16px;
  margin-top: 10px;
}
.head-p {
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 18px;
  color: #0C67FF;
  
}
.head-p2{
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 12px;
  margin-left: 5px;
  margin-bottom: 2px;
}

.prompt-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: 16px;
  margin-top: 14px;

}

.prompt-list-li {
  margin-right: 10px;
  height: 42px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px #DBE5EF;
  border-radius: 10px 10px 10px 10px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 42px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 10px;
  cursor: pointer;
}

.prompt-active {
  background: #226CFF;
  box-shadow: 0px 4px 12px 0px #DBE5EF;
  color: #ffffff;
}

/deep/ .el-textarea__inner {
  height: 520px;
  font-size: 17px;
  color: #000000;
  background: #F9FAFB;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #D7E4F3;
}

/deep/ .el-textarea__inner:focus {
  border-color: #409EFF;
  outline: 0;
}

.bottom-tip {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.tip-p {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
  line-height: 14px;
  margin-left: 2px;
  flex-grow: 1;
}

.tip-fresh {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 14px;
  cursor: pointer;
}

.active-nav {
  font-weight: 600;
  color: #000000;
}

.div-sub {

  display: flex;
  flex-direction: row-reverse;
  margin-top: 30px;
  margin-bottom: 30px;

}
.cancle-btn{
  width: 280px;
height: 52px;
border-radius: 10px 10px 10px 10px;
border: 1px solid #226CFF;
font-size: 16px;
background-color: #ffffff;
font-family: PingFang SC-Semibold, PingFang SC;
font-weight: 600;
color: #226CFF;
text-align: center;
margin-right: 15px;
}
/deep/ .el-button {
  width: 280px;
  height: 52px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

/deep/.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {

  background-color: #a0cfff;
  border-color: #a0cfff;
}



.div-dialog {
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: column;
}

.close_dialog {
  width: 36px;
  height: 36px;

  align-self: flex-end;
  position: absolute;
  right: 12px;

}

.dialog-head {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 20px;
}

.dialog-head-p {
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);

}

.dialog-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: auto;
  
  margin-top: 10px;
  justify-content: center;
  align-items: center;
 
  padding-left: 10px;
  padding-right: 10px;
}
.dialog-p4 {
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
}

.dialog-p5 {
  font-size: 15px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 15px;
  margin-top: 12px;
}

.dialog-bottom {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 19px;
  margin-right: 19px;
}

.dialog-negative {
  flex: auto;
  height: 52px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #D7E4F3;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  line-height: 52px;
  text-align: center;
}



.dialog-positive {
  flex: auto;
  height: 52px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  font-size: 14px;
  border: none;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 52px;
  text-align: center;
  margin-left: 15px;
}



/deep/.el-dialog {

  width: 400px;
  height: 260px;
  background: linear-gradient(180deg, #EAF1FA 0%, #FFFFFF 100%);
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}

/deep/.el-dialog__header {

  display: none;

}

/deep/.el-dialog__body {

  height: 100%;
  padding: 0;
}

.pop-content {
  display: flex;
}</style>